.backgroundContainer
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  width: 100%
  height: 100%
  mix-blend-mode: screen
  //overflow: hidden
