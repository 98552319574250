@import 'styles/site.sass'

.Main
  position: relative
  flex: 1
  max-width: units(70)
  @include min($screen-m)
    padding-right: $space-l
  
  @include max($screen-l)
    padding-bottom: units(8)

  @include min($screen-l)
    padding-bottom: units(4)
  
  .block
    display: none
    width: $block-width
    height: units(2.5)
    margin-bottom: units(3)
    background: $black

  h1
    @extend %heading-xl

  h4
    @extend %body-m
    @extend %bold
    margin-bottom: size(2)
    margin-top: -size(12)

  &.small
     h1
      @extend %heading-l
